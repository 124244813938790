import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';
import Modal from 'react-modal';
import { translate } from 'utils/utils';
import { toJS } from 'mobx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class ProductsAssociatedModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= 600,
        }
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                // width: window.innerWidth <= '850px' ? '90%' : '40%',
                //height: '80%'
                maxHeight: '100%',
                maxWidth: '90%',
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    handleCloseClick = () => {
        const {setAssociatedStage} = this.props.myShopApiStore;
        setAssociatedStage(0);
        this.props.closeModal();
    }
    
    getAgorotFormat = (num) => {
        const numFixed2 = (Math.floor(num)- num).toFixed(2);
        const l = numFixed2.toString().length;
        const agorot = numFixed2[l-2] + numFixed2[l-1];
        return agorot;
    }

    componentDidMount = () => {
        this.setState({ isMobile: window.innerWidth <= 600 })
    }

    render() {
        const { modalIsOpen, closeModal, itemList, handleAddClick, t } = this.props;
        const { shop, shopData, thisCart } = this.props.myShopApiStore;
        const { isRTL } = this.props.ifaceMngr;

        const { currencyName } = shopData.country;

        const cartHasIds = thisCart.cartItemList.reduce((acc, item)=>{ acc.push(item.item.id); return acc;},[]);
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.handleCloseClick}>
                <div className={style.ModalContainer}>
                    <div className={style.Header}> {shopData.associatedDescription} </div>
                    <div className={style.CartProducts}>
                    {
                        itemList?.filter(item=>!cartHasIds.includes(item.id)).map((oItem, index) => {
                            const item = toJS(oItem);
                            const shekels = item?.unitTypes?.length ? item.unitTypes[0].price : ''
                            const isEditableItem = !!item?.shopProductOptions?.length || !!item?.shopToppings?.length || !!item?.shopBaseToppings?.length;
                            translate(item, this.props.ifaceMngr.i18nextLng);

                            return(
                                <div className={style.ItemContainer} key={index}>
                                    <div onClick={() => {handleAddClick(item, isEditableItem);} }>
                                        <div className={style.ItemImage}>
                                            <LazyLoadImage effect='blur' src={item.product.image} />
                                            {  
                                                !shop.withoutPrices &&
                                                    <div className={style.PriceCircle} 
                                                        style={{
                                                            flexDirection: (isRTL ? 'row' : 'row-reverse'), 
                                                            [isRTL ?  'right' : 'left']: '-5%'
                                                        }}
                                                    >
                                                        <div className={style.Agorot}>
                                                            {this.getAgorotFormat(shekels)}
                                                        </div>
                                                        <div className={style.Shekels}>
                                                            {Math.floor(shekels).toString()}
                                                        </div> 
                                                        <div className={style.Curr}>
                                                            { currencyName }
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        <div className={style.ItemName}>{item?.product?.name}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    <div className={style.NoThanksFooter}>
                        <span onClick={closeModal}>{shop.withoutPrices ? t('continue') : t('go to pay')}</span>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(ProductsAssociatedModal);